<template>
    <div class="d-flex mt-2 calendar-main">
        <div class="w-25 me-4 calendar-content">
            <div class="d-flex flex-column justify-content-between align-items-center m-4 h-100">
                <button class="btn-create-appointment primary text-button w-100 " @click="openCreateAppt()">
                    <span class="rounded-add-custom material-icons-outlined">add</span>
                    <span class="additional-counselor-text">Create Appointment</span>
                </button>
                <div class="w-100 mb-5">
                    <CalendarFilters
                        :clients='clients'
                        @handleView='handleView'
                        @searchByClients='(e) => handleView(e)'
                        @searchByTags='(e) => handleView(e)'
                    />
                </div>
                <!-- <div class="w-100 mt-3">
                    <button class='weight-600 btn-sync-google-calendar w-100 d-flex align-items-center' @click='getCalendarAccessCode'>
                        <span class="material-symbols-outlined me-1 fs-1">sync</span>{{ syncBtnText }}
                    </button>
                    <div class="d-flex justify-content-center justify-content-center align-items-center mt-2" v-if='calendarSync'>
                        <input
                            class='right-10 google-hide-personal color'
                            id='hideGoogleEvents'
                            name='hideGoogleEvents'
                            type='checkbox'
                            :disabled="!calendarSync"
                            @change="hideGoogleEvents($event)"
                            :checked="hideGoogle"
                            style="top: 0"
                        />
                        <label class='pointer weight-600' for='hideGoogleEvents'>
                            Hide Personal Calendar Events
                        </label>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="w-75 calendar-content" :class="{'content-schedule-preview' : (!showCreateAppointmentForm && !showAppointmentDetail)}">
            <div class="schedule-screen-calendar-container">
            <ejs-schedule
                v-if='!loading && !showCreateAppointmentForm && !showAppointmentDetail'
                id='Schedule'
                ref='schedule'
                :actionComplete='onActionComplete'
                :agendaDaysCount='isAgenda ? 1 : 7'
                :created='onScheduleCreated'
                :currentView='currentView'
                :enablePersistence='enablePersistence'
                :endHour='workHours.end'
                :eventClick='cellClickHandler'
                :eventRendered='onEventRendered'
                :eventSettings='eventSettings'
                :popupOpen='onPopupOpen'
                :selectedDate='selectedDate'
                :startHour='workHours.start'
            >
                <e-views v-if='isAgenda'>
                    <e-view option='Agenda' allowVirtualScrolling='true'></e-view>
                </e-views>
            </ejs-schedule>
        </div>
            <div class="content-create-appointment">
                <div v-if="showCreateAppointmentForm">
                    <ejs-tooltip
                        class="tooltipcontainer"
                        content="Close"
                        target="#target"
                        cssClass="customtooltip"
                    >
                        <span id="target" class="material-symbols-outlined icon-close" @click="showCreateAppointmentForm = false">close</span>
                    </ejs-tooltip>
                    <CreateAppointment
                        :open='newEventModal'
                        :date='selectedTimeSlot'
                        @close='close()'
                        @savedAppt='handleView'
                        @savedEvent='getEvents'
                        @getClientServiceFees='getServicesForClient'
                        @getAllServices='getServices'
                        @clientSelected='disableClient'
                        @clientDeleted='enableClient'
                        :clinicians='clinicians'
                        :clientList='clients'
                        :active_clients='active_clients'
                        :services='services'
                        :offices='offices'
                        :rooms='rooms'
                        :clientId='clientId'
                        :apptType='apptType'
                        :activeUsers='users'
                    />
                </div>
                <div v-if="showAppointmentDetail">
                    <ejs-tooltip
                        class="tooltipcontainer"
                        content="Close"
                        target="#target"
                        cssClass="customtooltip"
                    >
                        <span id="target" class="material-symbols-outlined icon-close" @click="closeEventDetailModal">close</span>
                    </ejs-tooltip>
                    <AppointmentEditor
                        :initialEvent="selectedEvent"
                        @close="closeEventDetailModal"
                    />
                </div>
            </div>
        </div>
        <!-- <CreateEventModal
            :open='newEventModal'
            :date='selectedTimeSlot'
            @close='newEventModal = false'
            @savedAppt='handleView'
            @savedEvent='getEvents'
            @getClientServiceFees='getServicesForClient'
            @getAllServices='getServices'
            @clientSelected='disableClient'
            @clientDeleted='enableClient'
            :clinicians='clinicians'
            :clientList='clients'
            :active_clients='active_clients'
            :services='services'
            :offices='offices'
            :rooms='rooms'
            :clientId='clientId'
            :apptType='apptType'
            :activeUsers='users'
        />
        <EventEditor
            v-if="eventDetailModal"
            :initialEvent="selectedEvent"
            @close="closeEventDetailModal"
        /> -->
    </div>
    <!-- <div>
        <div class='cal-header flex space-between'>
            <CalendarFilters
                :clients='clients'
                @handleView='handleView'
                @searchByClients='(e) => handleView(e)'
                @searchByTags='(e) => handleView(e)'
            />
            <div>
                <button class='weight-600 leaf-green-bg' @click='getCalendarAccessCode'>
                    <span class='material-icons-outlined'>sync_alt</span>&nbsp; {{ syncBtnText }}
                </button>
                <div class="flex center top-5" v-if='calendarSync'>
                    <input
                        class='right-10 google-hide-personal color'
                        id='hideGoogleEvents'
                        name='hideGoogleEvents'
                        type='checkbox'
                        :disabled="!calendarSync"
                        @change="hideGoogleEvents($event)"
                        :checked="hideGoogle"
                        style="top: 0"
                    />
                    <label class='white-text pointer weight-600' for='hideGoogleEvents'>
                        Hide Personal Calendar Events
                    </label>
                </div>
            </div>
        </div>
        <ejs-schedule
            v-if='!loading'
            id='Schedule'
            ref='schedule'
            :actionComplete='onActionComplete'
            :agendaDaysCount='isAgenda ? 1 : 7'
            :created='onScheduleCreated'
            :currentView='currentView'
            :enablePersistence='enablePersistence'
            :endHour='workHours.end'
            :eventClick='cellClickHandler'
            :eventRendered='onEventRendered'
            :eventSettings='eventSettings'
            :popupOpen='onPopupOpen'
            :selectedDate='selectedDate'
            :startHour='workHours.start'
        >
            <e-views v-if='isAgenda'>
                <e-view option='Agenda' allowVirtualScrolling='true'></e-view>
            </e-views>
        </ejs-schedule>
        <CreateEventModal
            :open='newEventModal'
            :date='selectedTimeSlot'
            @close='newEventModal = false'
            @savedAppt='handleView'
            @savedEvent='getEvents'
            @getClientServiceFees='getServicesForClient'
            @getAllServices='getServices'
            @clientSelected='disableClient'
            @clientDeleted='enableClient'
            :clinicians='clinicians'
            :clientList='clients'
            :active_clients='active_clients'
            :services='services'
            :offices='offices'
            :rooms='rooms'
            :clientId='clientId'
            :apptType='apptType'
            :activeUsers='users'
        />
        <EventEditor
            v-if="eventDetailModal"
            :initialEvent="selectedEvent"
            @close="closeEventDetailModal"
        />
    </div> -->
</template>

<script>
    import dayjs from 'dayjs';
    import Vue from 'vue';
    import { mapState } from 'vuex';
    import {
        SchedulePlugin,
        Day,
        Week,
        WorkWeek,
        Month,
        Agenda,
        RecurrenceEditor,
        TimelineMonth
    } from '@syncfusion/ej2-vue-schedule';
    import {getHSLFormatFromRgbString,hexToHSL,} from '@/util/helpers/common.helpers.js';
    import { avatarColorOptions } from '@/util/globalConstants'
    import CreateEventModal from '../calendar/CreateEventModal.vue';
    import EventDetailModal from '@/components/calendar/EventDetailModal';
    import { EventType } from '@/util/globalConstants';
    import { companies, offices, appts, rooms, users, settings, apptItems } from '@/util/apiRequests';
    import CalendarFilters from '@/components/calendar/CalendarFilters';
    import Treeselect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import { DialogPlugin } from '@syncfusion/ej2-vue-popups';
    import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
    import AppointmentEditor from '@/components/calendar/AppointmentEditor/AppointmentEditor';
    import CreateAppointment from '@/components/calendar/CreateAppointment.vue'

    Vue.use(DialogPlugin);
    Vue.use(ButtonPlugin);
    Vue.use(SchedulePlugin);

    export default {
        name: 'Schedule',
        components: { AppointmentEditor, EventDetailModal, CreateEventModal, CreateAppointment, Treeselect, CalendarFilters },

        data() {
            return {
                storeduser: this.$store.state.user,
                userAppointmentColor:  '#DFEBFF',
                defaultAppointmentColor:  '#DFEBFF',
                agenda: false,
                loading: 1,
                events: [],
                appts: [],
                clinicians: [],
                clients: [],
                active_clients: [],
                services: [],
                offices: [],
                rooms: [],
                currentStartDate: null,
                newEventModal: false,
                eventDetailModal: false,
                selectedEvent: null,
                selectedTimeSlot: new Date(),
                enablePersistence: this.isAgenda ? false : false,
                workHours: {
                    start: '7:00',
                    end: '19:00'
                },
                currentviews: ['Day', 'Week', 'WorkWeek', 'Month', 'Agenda'], // , RecurrenceEditor
                currentView: 'Day',
                apptView: 0,
                message: '',
                searchBar: null,
                selectedDate: null,
                selectedLocation: -1,
                client: null,
                tag: null,
                tags: [
                    { id: 1, name: 'Anger Management' },
                    { id: 2, name: 'Family' },
                    { id: 3, name: 'Group' },
                    { id: 4, name: 'Suncoasts' }
                ],
                searchTags: [],
                clientId: null,
                apptType: null,
                value: null,
                searchValue: null,
                hideGoogle: false,
                users: [],
                autoGenerateInvoices: false,
                wth_list_view : 'All Appointments',
                wth_search_value: '',         // client name
                wth_place_of_service: 'all',     // office v telehealth
                getting_google: false,
                showCreateAppointmentForm: false,
                showAppointmentDetail: false
            };
        },
        props: {
            isAgenda: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState({
                calendarSync: state => state.oauth
            }),
            syncBtnText() {
                if (this.calendarSync) {
                    return 'Unsync from Google Calendar';
                } else return 'Sync to Google Calendar';
            },
            accessCode() {
                return this.$route.query.code;
            },

            eventSettings() {
                return {
                    dataSource: [...this.appts, ...this.events],
                    fields: {
                        startTime: { name: 'dayt_appt_start' },
                        endTime: { name: 'dayt_appt_end' },
                        subject: { name: 'title' }
                    },
                  
                };
            },

            ...mapState({
                user: 'user'
            }),
        },
        // TODO: Create a store entry for Google tokens that is hydrated from localStorage
        // TODO: When loading into the page, check the store for Google tokens
        // TODO: If Google tokens already exist, go ahead and call the API to retrieve events/transform them into our Event object

        methods: {
            enableClient(client){
                /**
                 * @type {object}
                 * @property {string} client.id
                 * @property {string} client.name
                 * @property {string} client.isDisabled
                 */

                let index = this.active_clients.findIndex(c => c.id == client.info.id);
                if(index > -1){
                    let copy = this.active_clients[index]
                    copy.$isDisabled = false;
                    this.$set(this.active_clients, index, copy);
                }
            },

            disableClient(clients){
                /**
                 * @type {array}
                 * @property {object} client
                 * @property {string} client.id
                 * @property {string} client.name
                 * @property {string} client.isDisabled
                 */

                //handles "individual" appt
                if(!Array.isArray(clients)){
                    this.active_clients.forEach((client) => client.$isDisabled = false);
                    let index = this.active_clients.findIndex(c => c.id == clients.id);
                    if(index > -1){
                        let copy = this.active_clients[index]
                        copy.$isDisabled = true;
                        this.$set(this.active_clients, index, copy);
                    }
                    return
                }

                //handles "group" appt
                this.active_clients.forEach((client) => client.$isDisabled = false);
                clients.forEach(client => {
                    let index = this.active_clients.findIndex(c => c.id == client.id);
                    if(index > -1){
                        let copy = this.active_clients[index]
                        copy.$isDisabled = true;
                        this.$set(this.active_clients, index, copy);
                    }
                });
            },
            async getUsers() {
                let res = await this.$api.get(users.getActiveUsers());
                this.users = res.data;
                this.$store.commit('eventEditor/setUsers', res.data);
            },
            async hideGoogleEvents(e) {
                switch (e.target.checked) {
                    case true:
                        this.hideGoogle = e.target.checked;
                        await this.getEvents();
                        break;
                    case false:
                        if (this.calendarSync) {
                            this.hideGoogle = e.target.checked;
                            this.$toasted.success('Showing Google calendar events');
                            await this.getGoogleEvents();
                        }
                        break;
                }
            },

            async getCalendarAccessCode() {

                // 1. calendarSync default to false
                if(this.calendarSync){
                    await this.getEvents(); // just makes an api call to get events, which starts from a fresh state => not my idea
                    await this.$api.delete(appts.deleteGoogleTokens());
                    this.$store.commit('clearGoogleTokens');
                    this.$toasted.success('Unsyced calendar from Google');

                    return
                }

                // 2. open google oauth window in current tab
                let res = await this.$api.get(appts.googleOauthUrl());
                window.open(res.data.url, '_self');

                // 3. google oauth  redirects back to Calendar page, triggering the created() hook
                // 4. get google access token from access code from URL ..... computed property ----> this.accessCode
                // 5. getCalendarTokens()
                // 6. getGoogleEvents()

            },

            async getCalendarTokens() {
                // check if tokens already exists in store
                if (this.calendarSync) {
                    await this.getGoogleEvents();
                } else {
                    //check if api was successful
                    try {
                        const res = await this.$api.post(appts.googleOauthTokens(), {
                            accessCode: this.accessCode
                        });

                        //create something in store
                        if (res.status < 300) {
                            this.$store.commit('setGoogleOAuthTokens', res.data);
                            await this.getGoogleEvents();
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }
            },

            async getGoogleEvents() {
                if (!this.getting_google) {
                    this.getting_google = true;

                    const start = this.currentStartDate

                    const timesToGet = {
                        'Day': {
                            value: 1,
                            unit: 'days'
                        },
                        'Week': {
                            value: 7,
                            unit: 'days'
                        },
                        'WorkWeek': {
                            value: 5,
                            unit: 'days'
                        },
                        'Month': {
                            value: 42,
                            unit: 'days'
                        },
                        'Agenda': {
                            value: 1,
                            unit: 'days'
                        }
                    }
                    const { value, unit } = timesToGet[this.currentView];

                    const end = dayjs(start).add(value, unit).toDate()

                    const res = await this.$api.post(appts.getGoogleEvents(), {
                        tokens: this.calendarSync,
                        start,
                        end
                    });
                    if (res.status >= 200 && res.status < 300) {
                        this.events = [...this.events, ...res.data];
                        this.populateCalendar(this.events, 'event');
                    }
                    this.getting_google = false;
                }
            },

            cellClickHandler(args) {
                if (this.isAgenda == true) {
                    //
                }
            },

            //use this function to populate cells on Calendar
            populateCalendar(data, type) {
                switch (type) {
                    case 'appt': {
                        return data.map((appt) => ({
                            title: appt.appointment,
                            appt_type: appt.appt_type,
                            appt_notes: appt.appt_notes,
                            type: EventType.APPOINTMENT,
                            RecurrenceRule: appt.recurrenceRule,
                            //CategoryColor: '#c191e6',
                            ...appt
                        }));
                    }
                    case 'event': {
                        return data.map((event) => {
                            return {
                                ...event,   // don't touch this!
                                title: event.event,
                                appt_type: event.appt_type,
                                appt_notes: event.description,
                                type: EventType.EVENT,
                                // CategoryColor: '#c191e6',
                                location: parseInt(event.location),
                                users:  event.users || [],
                                user_color:  event.users?.meetingHost?.user_color ?? null
                            }
                        });
                    }
                }
            },

            //filters appts based on in person or telehealth
            async handleOfficeFilter(e) {
                this.selectedLocation = parseInt(e.target.value);
                this.handleView();
            },

            //filters appt based on view selected

            clientDesc({ name }) {
                if (name) {
                    name = name.split(' ').reverse();
                    name[0] = `${name[0]}`;
                    return name.join(', ');
                }
                return 'no name';
            },

            async getEvents() {
                try {
                    if(this.searchValue){
                        this.events = [];
                    }else {
                        const res = await this.$api.get('/events', {
                            params: {
                                u: this.user.id,
                                s: this.currentStartDate,
                                e: this.currentEndDate,
                                t: this.selectedLocation,
                                v: this.apptView
                            }
                        });
                        // this.events.users = JSON.parse(this.events.users);
                        this.events = this.populateCalendar(res.data.map(e => ({...e, users: e.users})), 'event');
                    }
                    this.eventDetailModal = false;
                } catch (err) {
                    console.error(err);
                }
            },

            async getAppointments() {
                try {
                    const res = await this.$api.get('/appts', {
                        params: {
                            u: this.user.id,
                            s: this.currentStartDate,
                            e: this.currentEndDate
                        }
                    });
                    this.appts = this.populateCalendar(res.data, 'appt');
                } catch (err) {
                    console.error(err);
                }
                this.eventDetailModal = false;
            },

            async getClinicians() {
                try {
                    const res = await this.$api.get(companies.getClinicians(this.user.company_id));
                    this.clinicians = res.data;
                } catch (err) {
                    //this.$toasted.error('Could not retrieve the list of clinicians');
                }
            },

            async getClients() {
                try {
                    const res = await this.$api.get(companies.getClients(this.user.company_id));
                    this.clients = res.data;
                    this.$store.commit('eventEditor/setClients', res.data);
                } catch (err) {
                    this.$toasted.error('Could not retrieve the list of clients');
                }
            },

            async get_active_clients() {
                try {
                    const res = await this.$api.get('/clients/active-clients');
                    this.active_clients = res.data;
                    this.active_clients.forEach((client) => {

                        // by default set the client to enabled
                        client.$isDisabled = false

                        // if coming from "Schedule Now" from client overview
                        // then set the client to disabled
                        if(client.id == this.clientId){
                            client.$isDisabled = true
                        }
                    });
                } catch (err) {
                    //this.$toasted.error('Could not retrieve the list of clients');
                }
            },

            async getRooms() {
                try {
                    const res = await this.$api.post(rooms.getRooms(), {
                        criteria: {
                            filter: {},
                            sort: {
                                'rooms.dayt_create': 'Ascending'
                            },
                            page: {
                                num_per_page: 10000,
                                page_num: 1
                            },
                            date_range: {
                                start_date: '',
                                end_date: ''
                            },
                            search: {
                                like_all: ''
                            }
                        }
                    });
                    this.rooms = res.data.rows;
                    this.$store.commit('eventEditor/setRooms', res.data.rows);
                } catch (err) {
                    console.log(`error`, err);
                }
            },

            async getServices() {
                //gets services & associated service_fees at the company level
                try {
                    let level_id = this.$store.state.user.company_id;
                    let level = 'company';
                    const res = await this.$api.get(`/service_fees/list/${level}/${level_id}`);
                    this.services = res.data.filter((service) => {
                        if (service.code_type == 'service') {
                            return true;
                        } else return false;
                    });
                    this.$store.commit('eventEditor/setServices', this.services);
                    // this.$store.dispatch('progressNote/setServicesList', this.services);
                    // await this.$store.dispatch('progressNote/getCancelNoShowServiceCodes')
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of services');
                }
            },

            async getServicesForClient(clientId) {
                //gets services & associated service_fees at the client level
                try {
                    let level_id = clientId;
                    let level = 'client';
                    const res = await this.$api.get(`/service_fees/list/${level}/${level_id}`);
                    let client_fees = res.data.filter((fee) => fee.level === 'client');

                    //iterate thru list of client_fees
                    for (let item of client_fees) {
                        let index = this.services.findIndex((e) => e.service_code_id == item.service_code_id);
                        if (index > -1) {
                            //if the element exists, update services for THIS client only
                            this.$set(this.services, index, item);
                        }
                    }
                } catch (err) {
                    this.$toasted.error('Could not retrieve the list of services');
                }
            },

            async getOffices() {
                try {
                    const res = await this.$api.post(offices.getOffices(), { criteria: {} });
                    this.offices = res.data.rows;
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of offices');
                }
            },

            async updateEvent(selectedApptId, client) {
                await this.handleView();
                this.selectedEvent = this.appts.find((appt) => appt.appt_id == selectedApptId);
                //now, we toast
                // this.$toasted.success(`${client.name} has been removed the appointment`);
                this.$toasted.success(`Appointment has been updated`);
            },

            async handleInvoicesCreated(selectedApptId) {
                this.selectedEvent = this.appts.find((appt) => appt.appt_id === selectedApptId);
                await this.handleView();
                //keeps modal open after invoice is created
                this.eventDetailModal = true;
            },

            async getAllAppts(e) {
                try {
                    // this.apptView = 0;
                    const res = await this.$api.get(apptItems.getApptItems(),{
                        params: {
                            startDate: this.currentStartDate,
                            endDate: this.currentEndDate,
                            filters: {
                                search_value: this.wth_search_value,         // client name
                                place_of_service: this.wth_place_of_service,     // office v telehealth
                                list_view: this.wth_list_view,            // all vs my appts
                            }
                        }
                    })
                    // const res = await this.$api.get(appts.getAll(), {
                    //     params: {
                    //         search: this.searchValue ? this.searchValue : '',
                    //         u: this.user.id,
                    //         t: this.selectedLocation == -1 ? [0, 1] : this.selectedLocation,
                    //         s: this.currentStartDate,
                    //         e: this.currentEndDate
                    //     }
                    // });
                    this.appts = this.populateCalendar(res.data, 'appt');

                    if (!this.appts.length) {
                        this.$toasted.error('No appointments in the selected date range.');
                    }
                } catch (err) {
                    console.error(err);
                }
            },
             getUserColor(counselors,user){
                // let loggedInUserDetail = await this.getLoggedInUserDetail(user.id);
                let index=counselors.findIndex(counselor=>counselor.id==user.id)
                if(index!==-1){
                    return this.userAppointmentColor
                }
                return null
            },
            async getLoggedInUserDetail(userId) {
                try {
                    const res = await this.$api.get(users.getUserDetails(userId));
                    return { user_detail: res.data };

                } catch (e) {
                    console.error(e);
                }
            },
           onEventRendered: function(args) {
            
                let apptStartTime=new Date(args.data.dayt_appt_start).toLocaleTimeString()
                apptStartTime=apptStartTime.split('')
                var count = 0;
            apptStartTime.forEach((val, index) => {
                if (val == ':') {
                    count++;
                }

                if ((val != 'p' && val != 'm' && val != 'a')) {
                    if (count >= 2) {
                        apptStartTime[index] = ''
                    }

                }


            })


            apptStartTime = apptStartTime.join('')
            let apptEndTime = new Date(args.data.dayt_appt_end).toLocaleTimeString()
            apptEndTime = apptEndTime.split('')

            count = 0;

            apptEndTime.forEach((val, index) => {
                if (val == ':') {
                    count++;
                }

                if ((val != 'p' && val != 'm' && val != 'a')) {
                    if (count >= 2) {
                        apptEndTime[index] = ''
                    }

                }

            })
                
                apptEndTime=apptEndTime.join('')
                const userColor= this.getUserColor(args.data.counselors,this.storeduser)
                let color = userColor?userColor:this.defaultAppointmentColor;
                args.element.style.backgroundColor = color;
                args.element.style.minHeight="3.5rem";
                args.element.style.color = this.getTextColorForBackground(color);
                let font_color = this.getTextColorForBackground(color);
                let colorOptions=avatarColorOptions.filter(item=>item!==color)
                if(this.currentView==='Day'){
                    const {clients,counselors}=args.data
                    let clientsArray=clients.map((item)=>{
                        return  {code:`${item.first_name[0]}${item.last_name[0]}`,toolTipContent:item.first_name+ ' '+item.last_name,type:'client'}})
                    let counselorsArray=counselors.length>0?counselors.map((item)=>{
                        const nameArr=item.name.split(' ')
                        return  {code:`${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`,toolTipContent:item.name,type:'counselor'}}):[]

                        let participants=[...clientsArray,...counselorsArray]
                        let roundedHTMLAvatars=``,
                        shortFormHTML=`<div>`;
                        let firstSixParticipans=participants.slice(0,6)
                        let restParticipants=participants.slice(6)
                        firstSixParticipans.forEach((item,idx)=>{
                            let index=idx
                            if(idx>colorOptions.length-1){
                                index=idx%colorOptions.length
                            }
                            if(item.type==='client')
                            roundedHTMLAvatars+=`
                            <div class='appointment-bar-avatar' style="background-color:${colorOptions[index]}" v-b-tooltip.hover title="${item.toolTipContent}">
                                <span>${item.code.toUpperCase()}<span>
                            </div>`
                            else{
                                roundedHTMLAvatars+= `<div class='appointment-bar-avatar' style="background-color:${colorOptions[index]}" v-b-tooltip.hover title="${item.toolTipContent}">
                                <span>${item.code.toUpperCase()}<span>
                                    
                            </div>`
                            }
                        })
                        if(restParticipants.length>0)
                        roundedHTMLAvatars+= `<div class='appointment-bar-avatar' style="background-color:${colorOptions[1]}" v-b-tooltip.hover title="Click to view all ${participants.length+' participant(s)'}">
                                <span>${'+'+restParticipants.length}<span>
                                    
                            </div>`
                        shortFormHTML+=`</div>`
                        let participantsCount=participants.length
                    args.element.innerHTML=`
                        <div class="e-appointment-details day-e-appointment-details" id='xyz' style='display:flex;gap:1rem;'>
                        <div style='display:flex;flex-direction:column'>
                            <div class="e-subject" style="margin-left:0;color:${font_color};">${args.data.appointment}</div>
                            <div class="e-time">${apptStartTime} - ${apptEndTime}</div>
                        </div>`+`
                        <div class='appointment-bar-avatars-container' >    
                        ${roundedHTMLAvatars}
                        
                    </div>

                        <div class='appointment-bar-avatars-container-v2'>    
                            <div class='short-form-html'>
                                <div class='appointment-bar-avatar' style='background-color:${colorOptions[0]}'   title="Click to view all ${participants.length+' participant(s)'}">
                                    <span>+${participantsCount}</span>
                                    </div>
                         </div>
                    </div>
                        </div>`
                }
                else if(this.currentView==='Agenda'){
                    args.element.innerHTML=`
                        <div class="e-appointment-details agenda-e-appointment-details" style='display:flex;gap:2em;margin-left:2em'>
                        <div style='display:flex;flex-direction:column;align-items:start;'>
                            <div class="e-subject" style="margin-left:0;color:${font_color}">${args.data.appointment}</div>
                            <div class="e-time">${apptStartTime} - ${apptEndTime}</div>
                        </div>`+
                        `</div>`
                }
             },

            onPopupOpen: function(args) {
                if (args.type === 'QuickInfo' || args.type === 'Editor') {
                    args.cancel = true;
                    if (args?.data.Id) {
                        this.selectedEvent = args.data;
                        if (this.isAgenda == false) {
                            this.$router.push({
                                query: { apptId: this.selectedEvent.appt_id, startTime: args.data.start }
                            });
                        }
                        this.showAppointmentDetail = true;
                        this.eventDetailModal = true;
                    } else {
                        this.selectedTimeSlot = args.data.startTime;
                        this.showCreateAppointmentForm = true;
                        this.newEventModal = true;
                        this.getServices();
                    }
                }
            },

            createAppointment() {
                this.newEventModal = true;
                this.getServices();
            },

            onActionComplete: function(args) {
                switch (args.requestType) {
                    case 'dateNavigate':
                    case 'viewNavigate':
                        this.setDates();
                        break;
                    default:
                        break;
                }
            },

            async getMyAppts() {
                this.apptView = 1;
                try {
                    const res = await this.$api.get(appts.getMine(), {
                        params: {
                            search: this.searchValue ? this.searchValue : '',
                            t: this.selectedLocation == -1 ? [0, 1] : this.selectedLocation,
                            u: this.user.id,
                            s: this.currentStartDate,
                            e: this.currentEndDate
                        }
                    });
                    this.appts = this.populateCalendar(res.data, 'appt');
                    if (!this.appts.length) {
                        this.$toasted.error('No appointments in the selected date range.');
                    }
                } catch (err) {
                    console.log(err);
                }
            },

            async handleView(e) {
                if (e) {
                    if (e.searchValue) {
                        this.wth_search_value = e.searchValue
                    } else {
                        this.wth_search_value = ''
                    }
                }

                if (e && e.location) {
                    this.wth_place_of_service = e.location
                }
                if (e && e.view) {
                    this.wth_list_view = e.view
                }
                if (e) {
                    this.apptView = e.view;
                    this.selectedLocation = e.location;
                    this.searchValue = e.searchValue
                }

                // update store filters
                this.$store.commit('scheduler/updateFilters', {payload :{
                    search_value: this.wth_search_value,
                    place_of_service: this.wth_place_of_service,
                    appt_list_view: this.wth_list_view
                }})

                await this.getAllAppts(e); //get all company appts
                await this.getEvents();
                if (this.$store.state.oauth && !this.hideGoogle) {
                    await this.getGoogleEvents();
                }

                if (this.$route.query?.apptId) {
                    const selectedEvent = this.eventSettings?.dataSource.find(
                        (appt) => appt.appt_id == this.$route.query.apptId
                    );

                    if (selectedEvent) {
                        this.selectedEvent = selectedEvent;
                        this.showAppointmentDetail = true;
                        this.eventDetailModal = true;
                        return;
                    }

                    this.eventSettings?.dataSource.forEach((appt) => {
                        const hasAppt = appt.clients?.some((client) => client.appt_id == this.$route.query.apptId);
                        if (hasAppt) {
                            this.selectedEvent = appt;
                            this.showAppointmentDetail = true;
                            this.eventDetailModal = true;
                            return;
                        }
                    });
                }
            },

            setDates() {
                const currentDates = this.$refs.schedule.getCurrentViewDates();
                const currentviewndx = this.$refs.schedule.getCurrentViewIndex();
                this.currentView = this.currentviews[currentviewndx];

                if (this.isAgenda == false) {
                    if (this.$store.state.scheduler.calendar == undefined) {
                        this.$store.commit('scheduler/updateCalendar', {
                            payload: {}
                        });
                    }
                    this.$store.commit('scheduler/updateCurrentView', {
                        payload: this.currentView
                    });
                } else {
                    this.apptView = 1;
                }
                this.currentStartDate = dayjs(currentDates[0]).format('YYYY-MM-DDTHH:mm:ssZ');
                this.currentEndDate = dayjs(currentDates[currentDates.length - 1]).format('YYYY-MM-DDTHH:mm:ssZ');
                // updating start & end dates in vuex store
                this.$store.commit('scheduler/updateCurrentStartEndDate', {payload: {
                    start: this.currentStartDate,
                    end: this.currentEndDate,
                }})
                this.getEvents();
                this.handleView();
            },

            onScheduleCreated() {
                this.setDates();
            },

            async closeEventDetailModal() {
                try {
                    this.showAppointmentDetail = false;
                    this.eventDetailModal = false;
                    // await this.$router.replace({
                    //     ...this.$router.currentRoute,
                    //     query: {},
                    // });
                    await Promise.allSettled([
                        this.handleView(),
                        this.getEvents(),
                    ]);
                } catch (error) {
                    console.error(error);
                }

            },

            getTextColorForBackground(backgroundColor) {
                if (!/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(backgroundColor)) {
                    return '#FFFFFF';
                }

                const r = parseInt(`0x${backgroundColor[1]}${backgroundColor[2]}`);
                const g = parseInt(`0x${backgroundColor[3]}${backgroundColor[4]}`);
                const b = parseInt(`0x${backgroundColor[5]}${backgroundColor[6]}`);

                const luminance = r * 0.299 + g * 0.587 + b * 0.114;

                return luminance > 186 ? '#000000' : '#FFFFFF';
            },

            async checkAutoGenerateInvoices(){
                try {
                    const { data } = await this.$api.get(settings.getSetting('invoice_auto_generated', 'company', 'company'));
                    this.autoGenerateInvoices = data.value === '1';
                    this.$store.commit('eventEditor/setAutogenerateInvoice', data.value === '1');
                } catch (error) {
                console.error(error)
                }
            },
            
            close() {
                this.newEventModal = false;
                this.showCreateAppointmentForm = false;
            },
            openCreateAppt() {
                this.selectedTimeSlot = new Date();
                this.showCreateAppointmentForm = true;
                this.showAppointmentDetail = false;
            }
        },

        async created() {


            if (this.$route.query?.startTime) {
                /* Localhost weird code to make scheduler go to selected data, since we have enablePersistence on */

                let localStorageObj = JSON.parse(window.localStorage.getItem('scheduleSchedule'));
                if (localStorageObj) {
                    localStorageObj.selectedDate = this.$route.query.startTime;
                    window.localStorage.setItem('scheduleSchedule', JSON.stringify(localStorageObj));
                }
                this.selectedDate = new Date(this.$route.query.startTime);
            }

            await Promise.all([
                this.getClinicians(),
                this.getClients(),
                this.get_active_clients(),
                this.getServices('company'),
                this.getOffices(),
                this.getRooms(),
                this.getUsers(),
                this.checkAutoGenerateInvoices()
            ]);

            this.loading = 0;

            if (this.$store.state.scheduler.calendar && this.$store.state.scheduler.currentView != undefined) {
                this.currentView = this.$store.state.scheduler.currentView;
                if (this.$refs.schedule) {
                    this.$refs.schedule.changeCurrentView(this.currentView);
                }
            }

            if (this.accessCode && !this.calendarSync) {
                await this.getCalendarTokens();
            }
        },
        provide: {
            schedule: [Day, Week, WorkWeek, Month, Agenda, RecurrenceEditor, TimelineMonth]
        },

        watch: {
            newEventModal() {
                if (this.newEventModal == false) {
                    //reset services everytime modal closes
                    this.getServices();
                    this.clientId = null;
                    this.apptType = null;
                }
            },
            clients() {
                if (this.clients.length > 0) {
                    this.clients.forEach((client) => {
                        let person = {};
                        person.id = client.id;
                        person.label = client.name;
                    });
                }
            },
            '$route.params.clientId': {
                handler: function(clientId) {
                    if(clientId == null){
                        this.newEventModal = false;
                        return
                    }
                    this.clientId = this.$route.params.clientId;
                    this.apptType = this.$route.params.apptType;
                    this.showCreateAppointmentForm = true;
                    this.newEventModal = true;
                },
                deep: true,
                immediate: true
            }
        },

        async beforeCreate() {
            // check the store for the list of active clients
            if(this.$store.getters['scheduler/clientList'].length > 0 ){
                // set the list of active clients from the store
                this.active_clients = this.$store.state.scheduler.active_clients;
                return
            }

            if(this.$store.getters['scheduler/serviceList'].length > 0 ){
                // set the list of active clients from the store
                this.active_clients = this.$store.state.scheduler.active_clients;
                return
            }

        },
        async mounted(){
    let loggedInUserDetail = await this.getLoggedInUserDetail(this.storeduser.id);
    this.userAppointmentColor=loggedInUserDetail.user_detail.user_color
},
    };
</script>

<style scoped>
    @import '~@syncfusion/ej2-base/styles/material.css';
    @import '~@syncfusion/ej2-buttons/styles/material.css';
    @import '~@syncfusion/ej2-calendars/styles/material.css';
    @import '~@syncfusion/ej2-dropdowns/styles/material.css';
    @import '~@syncfusion/ej2-inputs/styles/material.css';
    @import '~@syncfusion/ej2-navigations/styles/material.css';
    @import '~@syncfusion/ej2-popups/styles/material.css';
    @import '~@syncfusion/ej2-vue-schedule/styles/material.css';

    .calendar-content.content-schedule-preview {
        overflow: auto;
    }
    .btn-sync-google-calendar .material-symbols-outlined {
        font-variation-settings:
        'FILL' 0,
        'wght' 100,
        'GRAD' 200,
        'opsz' 48
    }

    .material-symbols-outlined.icon-close {
        position: absolute;
        right: 10px;
        top: 14px;
        font-size: 1.26rem;
        cursor: pointer;
        z-index: 1;
        border-radius: 50%;
        background-color: #a61d21;
        color: white;
        padding: 0.38rem;
        font-variation-settings:
        'FILL' 0,
        'wght' 600,
        'GRAD' 0,
        'opsz' 48
    }
</style>
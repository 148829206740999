<template>
    <div v-if="$store.state.initialized == true && loading == false">
        <div class="dashboard" v-if="$store.state.user.role_id !== roles.ADMIN && $store.state.user.role_id!==roles.SUPER_ADMIN">
            <div class="clinician-dashboard">
                <div class="d-flex text-center clinician-dash-section-1">
                    
                    <!-- <div class="w-25 m-3 p-2 section opacity-95">
                        <img src="@/assets/px/clinician-dashboard/assigned-patients-clinician-dashboard.png" alt="" class="w-100 h-100">
                        <div class="d-flex justify-content-around">
                            <div>Concentric Progress spinner</div>
                            <div class="d-grid">
                                <div>Assigned Patients</div>
                                <div>60 People</div>
                                <div class="top-10">40 Male</div>
                                <div>20 Female</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="w-67 m-3 p-2 section section-alert opacity-95">
                        <div class="d-flex justify-content-around h-100">
                            <div class="d-flex align-items-center justify-content-center">
                                <b-tooltip target="alerts" placement="bottom" title="Alerts"></b-tooltip>
                                <div class="position-relative" id="alerts">
                                    <span class="material-icons notification-icon" id="bell-icon">notifications_none</span>
                                    <span for="bell-icon" class="position-absolute top-0 right-0 notification-icon-count">{{ alertsCount }}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-column align-items-start justify-content-center">
                                <div class="mx-1 alert-title">
                                    <span>Alerts</span>
                                </div>
                                <div class="mx-1 alert-switch-section" @click="toggle_mine_all" v-if="$store.state.user.role_id == 1">
                                    <div v-if="all_alerts" style="color: #FFFFFF;" class="d-flex align-items-center justify-content-between">
                                        <span class="mx-2 px-1 align-items-center justify-content-between">
                                            My Alerts
                                        </span>
                                        <span class="m-2 align-items-center justify-content-between alert-switch-toggle">
                                            on
                                        </span>
                                    </div>
                                    <div v-else style="color: #FFFFFF;" class="d-flex align-items-center justify-content-between">
                                        <span class="mx-2 px-1 align-items-center justify-content-between">
                                            Company Alerts
                                        </span>
                                        <span class="m-2 align-items-center justify-content-between alert-switch-toggle">
                                            on
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column align-items-start justify-content-center alert-view-section"> 
                                <div class="my-1 mx-1" v-if="$store.state.user.role_id == 1">
                                    <span v-if="all_alerts">
                                        Viewing My Alerts
                                    </span>
                                    <span v-else>
                                        Viewing Company Alerts
                                    </span>
                                </div>
                                <div class="w-100">                            
                                    <DashAlert
                                        v-for="(alert_type, key) in alert_types"
                                        :key="key"
                                        :alert_type="alert_type"
                                        :all_alerts="all_alerts">
                                    </DashAlert>
                                </div>
                            </div>
                            <div class="d-flex flex-column align-items-start justify-content-center">
                                <a class="mx-1 view-alerts-text" href="/alerts"> 
                                    <span>View my alerts</span>
                                    <span class='material-icons navigate-icon'>navigate_next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="w-33 m-3 p-2 section w-25 add-member-section opacity-95">
                        <router-link to="/add-member">
                            <div role="button" class="h-100 d-grid justify-content-center align-items-center align-content-center">
                                <div class="add-member-icon-section my-2">
                                    <span class="material-icons add-member-icon">add</span>
                                </div>
                                <div class="add-member-text my-2">
                                    Add Member
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="d-flex justify-content-between clinician-dash-section-2">
                    <div class="w-33 m-3 p-2 section opacity-95">
                        <div class="section-header d-flex justify-content-between">
                                <span class="title">Reminder (List to do)</span>
                                <b-tooltip target="add_reminder" placement="bottom" title="Add"></b-tooltip>
                                <span class='material-icons add-reminder-icon' @click="toggleReminderForm()" id="add_reminder">add_circle</span>
                        </div>
                        
                        <div class="position-relative px-2">
                            <div class="position-absolute w-100" style="z-index: 99;" v-if="reminderForm">
                                <div class="p-4 opacity-95 white-bg">
                                    <div class="my-2">
                                        <textarea placeholder="Write Reminder" class="input" v-model="reminderData.reminder"></textarea>
                                    </div>
                                    <div class="my-2">
                                        <select name="priority" id="" class="form-control input" v-model="reminderData.priority">
                                            <option selected disabled value="">Set Priority</option>
                                            <option value="High">High</option>
                                            <option value="Normal">Normal</option>
                                            <option value="Low">Low</option>
                                        </select>
                                    </div>
                                    <div class="my-2">
                                        <input type="date" name="due_date" id="" class="form-control input" v-model="reminderData.due_date">
                                    </div>
                                    <div class="d-flex">
                                        <button class="w-25 btn btn-primary" @click="createUserReminder" v-if="!reminderId">Save</button>
                                        <button class="w-25 btn btn-primary" @click="updateUserReminder" v-else-if="reminderId">Update</button>
                                        <button class="w-25 btn btn-cancel" @click="toggleReminderForm()">Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <div class="head-row">
                                <div class="d-flex content-head justify-content-between">
                                    <div class="w-45">Reminder</div>
                                    <div class="w-20">Priority</div>
                                    <div class="w-25">Due on</div>
                                    <div class="w-10"></div>
                                </div>
                            </div>
                            <div class="section-body mt-1 section-body-odd">
                                <div class="d-flex section-content-body justify-content-between" v-for="(reminderData, i) in userReminders" :key="reminderData">
                                    <div class="w-45 desc">
                                        <span>{{ reminderData.reminder }}</span>
                                    </div>
                                    <div class="w-20">
                                        <div class="priority-green text-center" v-if="reminderData.priority === 'High'">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span>High</span>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <span class="material-symbols-sharp priority-icon">
                                                        arrow_upward
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="priority-blue text-center" v-if="reminderData.priority === 'Normal'">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span>Normal</span>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <span class="material-symbols-sharp priority-icon">
                                                        remove
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="priority-red text-center" v-if="reminderData.priority === 'Low'">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span>Low</span>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <span class="material-symbols-sharp priority-icon">
                                                        arrow_downward
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-25 desc">
                                        {{ getFormattedDate(reminderData.due_date, 'DD/MM/YYYY', "DD-MMM, YYYY") }}
                                    </div>
                                    <div class="w-10">
                                        <b-button :id="'popover-button-variant'+i" variant="light" placement="bottom">
                                            <span class="material-icons menu-icon">more_vert</span>
                                        </b-button>
                                        <b-popover ref="popover" :target="'popover-button-variant'+i" variant="light" triggers="click blur" id="popover-button-variant">
                                            <div class="d-flex align-content-center border rounded bg-light p-2">
                                                <div class="w-100">
                                                    <!-- <div class="w-100 my-1">
                                                        <a href="" class="d-flex align-items-center">
                                                            <span class="material-symbols-outlined text-primary">
                                                                person
                                                            </span>
                                                            <span class="text-dark mx-2">Assign to other</span>
                                                        </a>
                                                    </div> -->
                                                    <div class="w-100 my-1">
                                                        <a href="javascript:void(0)" class="d-flex align-items-center" @click="editReminder( reminderData )">
                                                            <span class="material-symbols-outlined text-primary">
                                                                edit_square
                                                            </span>
                                                            <span class="text-dark mx-2">Edit</span>
                                                        </a>
                                                    </div>
                                                    <div class="w-100 my-1">
                                                        <a href="javascript:void(0)" class="d-flex align-items-center" @click="deleteReminderAlert( reminderData.id )">
                                                            <span class="material-symbols-outlined text-danger">
                                                                delete
                                                            </span>
                                                            <span class="text-dark mx-2">Delete</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Danger variant popover -->
                                        </b-popover>
                                    </div>
                                </div>
                                <div v-if="!userReminders || userReminders.length == 0" class="h-100 d-flex justify-content-center align-items-center">
                                    <span class="no-content-text">No Reminders Listed.</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="w-33 m-3 p-2 section opacity-95">
                        <div class="section-header">
                            <span class="title">
                                Recently viewed forms
                            </span>
                        </div>
                        <div class="section-body mt-1 section-body-even">
                            <div class="d-flex section-content-body" v-for="(form, formKey) in recentForms" :key="formKey">
                                <div class="w-90 desc">
                                    {{ form.string }}
                                </div>
                                <div class="desc">
                                    <b-tooltip :target="'view'+form" placement="bottom" title="View"></b-tooltip>
                                    <router-link :to="goToRecentForms(form)" :id="'view'+form">
                                        <span class="material-symbols-sharp eye-icon"> visibility </span>
                                    </router-link>
                                </div>
                            </div>
                            <!-- Dummy -->
                            <!-- <div class="d-flex section-content-body" v-for="form of [1,2,3,4,5,6,7,8,9]" :key="form">
                                <div class="w-90 desc">
                                    Client {{form}}, Test Polices & Consent For Treatment {{form}}.03.23
                                </div>
                                <div class="desc">
                                    <b-tooltip :target="'view'+form" placement="bottom" title="View"></b-tooltip>
                                    <a href="" :id="'view'+form">
                                        <span class="material-symbols-sharp eye-icon"> visibility </span>
                                    </a>
                                </div>
                            </div> -->
                            <!-- Dummy -->
                            <div v-if="!recentForms || recentForms.length == 0" class="h-100 d-flex justify-content-center align-items-center">
                                <span class="no-content-text">No Forms Viewed Recenly.</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-33 m-3 p-2 section opacity-95">
                        <div class="section-header">
                            <span class="title">
                                Recently viewed members
                            </span>
                        </div>
                        <div class="px-2">
                            <div class="head-row">
                                <div class="d-flex content-head">
                                    <div class="w-100">Patient Name</div>
                                </div>
                            </div>
                            <div class="section-body mt-1 section-body-odd">
                                <div class="section-content-body" v-for="(client, clientKey) in recentClients" :key="clientKey">
                                    <div class="w-100 d-flex">
                                        <div v-if="client.profileImage && client.profileImage !== 'no-image'">
                                            <img :src="client.profileImage" alt="" class="member-image">
                                        </div>
                                        <span v-if="client.profileImage === 'no-image' || !client.profileImage" style="" class="material-symbols-rounded border member-icon"> person </span>
                                        <span class="member-name left-5 d-flex align-items-center">
                                            <router-link :to="{ name: 'ClientOverview', params: { record_id: client.id } }">
                                                {{ `${client.last_name} ${client.first_name}` }}
                                            </router-link>    
                                        </span>
                                    </div>
                                </div>
                                <!-- Dummy -->
                                <!-- <div class="section-content-body" v-for="client of [1,2,3,4,5,6,7,8]" :key="client">
                                    <div class="w-100 d-flex">
                                        <span style="" class="material-symbols-rounded border member-icon"> person </span>
                                        <span class="member-name left-5 d-flex align-items-center">
                                            <a href="" disabled>Demo Client {{ client }}</a>
                                        </span>
                                    </div>
                                </div> -->
                                <!-- Dummy -->
                                <div v-if="!recentClients || recentClients.length == 0" class="h-100 d-flex justify-content-center align-items-center">
                                    <span class="no-content-text">No Clients Viewed Recenly.</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="d-flex justify-content-between" >
                    <div class="w-100">
                        <ScheduleForDashboard/>
                    </div>
                    <!-- <div class="w-67 m-3 p-2 section opacity-95">
                        <img src="@/assets/px/clinician-dashboard/my-schedule-preview-clinician-dashboard.png" alt="" class="w-100 h-100">
                    </div>
                    <div class="w-33 m-3 p-2 section opacity-95">
                        <img src="@/assets/px/clinician-dashboard/telehealth-statistics-clinician-dashboard.png" alt="" class="w-100 h-100">
                    </div> -->
                </div>
            </div>            
        </div>

        <div class="dashboard" v-if="$store.state.user.role_id == roles.ADMIN || $store.state.user.role_id==roles.SUPER_ADMIN">
            <div class="clinician-dashboard">
                <div class="d-flex justify-content-between clinician-dash-section-1">
                    <div class="w-33 m-3 p-2 section opacity-95">
                        <!-- <div style="width:100%">ss -->
                        <PatientCountChart/>
                    <!-- </div> -->
                    </div>
                    <div class="w-33 m-3 p-2 section opacity-95">
                        <div class="d-grid h-100">
                            <div class="d-flex py-4">
                                <div class="d-flex justify-content-center align-items-center m-3" style="background: #A51E22; color: white; border-radius: 50%; width: 100px;">
                                    <span class="material-symbols-outlined" style="font-size: 65px"> manage_accounts </span>
                                </div>
                                <div class="d-grid justify-content-left align-items-center w-75 align-content-center py-2">
                                    <p style="font-size: 35px; font-weight: 700; font-family: 'Roboto'">{{ serviceCoordinatorsCount }}</p>
                                    <p style="font-size: 18px; font-weight: 500; font-family: 'Roboto'; color: #6F757B;">Service Coordinators</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end px-4 align-items-center add-user">
                                <router-link to="/users?role=Clinician">
                                    <span style="color: #A51E22; font-size: 16px; font-weight: 500; font-family: 'Roboto'">View List</span>
                                    <span class="material-symbols-outlined" style="color: #A51E22;">arrow_forward_ios </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="w-33 m-3 p-2 section w-25 add-member-section opacity-95" @click="() => { $store.commit('modals/updateField', {
                                field: 'newUserModal',
                                value: !$store.state.modals.newUserModal
                            });
                        }
                    ">
                     <!-- to="/add-member" -->
                        <router-link to="">
                            <div role="button" class="h-100 d-grid justify-content-center align-items-center align-content-center">
                                <div class="add-member-icon-section my-2">
                                    <span class="material-icons add-member-icon">add</span>
                                </div>
                                <div class="add-member-text my-2">
                                    Add New User
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="d-flex justify-content-between admin-dash-section-2" >
                    <div class="w-67 m-3 p-2 section opacity-95">
                        <ScheduleForDashboard :isAdmin="true"/>
                    </div>
                    <div class="w-33 m-3 p-2 section opacity-95">
                        <div class="section-header">
                            <span class="title">
                                Recently viewed members
                            </span>
                        </div>
                        <div class="px-2">
                            <div class="head-row">
                                <div class="d-flex content-head">
                                    <div class="w-100">Patient Name</div>
                                </div>
                            </div>
                            <div class="section-body mt-1 section-body-odd">
                                <div class="section-content-body" v-for="(client, clientKey) in recentClients" :key="clientKey">
                                    <div class="w-100 d-flex">
                                        <div v-if="client.profileImage && client.profileImage !== 'no-image'">
                                            <img :src="client.profileImage" alt="" class="member-image">
                                        </div>
                                        <span v-if="client.profileImage === 'no-image' || !client.profileImage" style="" class="material-symbols-rounded border member-icon"> person </span>
                                        <span class="member-name left-5 d-flex align-items-center">
                                            <router-link :to="{ name: 'ClientOverview', params: { record_id: client.id } }">
                                                {{ `${client.last_name} ${client.first_name}` }}
                                            </router-link>    
                                        </span>
                                    </div>
                                </div>
                                <!-- Dummy -->
                                <!-- <div class="section-content-body" v-for="client of [1,2,3,4,5,6,7,8]" :key="client">
                                    <div class="w-100 d-flex">
                                        <span style="" class="material-symbols-rounded border member-icon"> person </span>
                                        <span class="member-name left-5 d-flex align-items-center">
                                            <a href="" disabled>Demo Client {{ client }}</a>
                                        </span>
                                    </div>
                                </div> -->
                                <!-- Dummy -->
                                <div v-if="!recentClients || recentClients.length == 0" class="h-100 d-flex justify-content-center align-items-center">
                                    <span class="no-content-text">No Clients Viewed Recenly.</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>            
        </div>

        <!-- Old Admin Dashboard -->

        <!-- <div class="dashboard" v-if="$store.state.user.role_id == 1">
            <div class="dashboard-alerts card-block">
                <div class="flex center space-between">
                    <div class="flex center">
                        <h2 class="section-header right-30">Alerts</h2>
                        <template>
                        <button class="secondary right-10" style="padding:3px 10px;" @click="toggle_mine_all">
                            <div v-if="all_alerts">
                                <span class="material-icons-outlined">filter_list</span> Show My Alerts
                            </div>
                            <div v-else>
                                <span class="material-icons-outlined">filter_list</span> Show Company Alerts
                            </div>
                        </button>
                        <div v-if="all_alerts"><i>(Viewing Company Alerts)</i></div>
                        <div v-else><i>(Viewing My Alerts)</i></div>
                        </template>
                    </div>
                    <a class="text-link" href="/alerts"
                        >See details for all alerts <span class="material-icons-outlined">arrow_forward</span></a
                    >
                </div>
                <div class="grid">
                    <div class="dash-alerts-wrapper">
                        <DashAlert
                            v-for="(alert_type, key) in alert_types"
                            :key="key"
                            :alert_type="alert_type"
                            :all_alerts="all_alerts"
                        ></DashAlert>
                    </div>
                </div>
            </div>
            <div class="flex">
                <div class="schedule card-block">
                    <h2 class="section-header">Schedule Preview</h2>
                    <div class="overview-appointments">
                        <Agenda />
                        <div class="align-right top-10" >
                            <router-link class="text-link margin-0" :to="{ name: 'Calendar' }"
                                >View Full Calendar</router-link
                            >
                        </div>
                    </div>
                </div>
                <div class="flex wrap right-col">
                    <div class="left-col">
                        <div class="dashboard-clients card-block">
                            <h2 class="section-header">Members</h2>
                            <div class="dash-client-search">
                                <Multiselect
                                    v-model="client"
                                    :options="clients"
                                    track-by="id"
                                    :custom-label="clientDesc"
                                    @select="(e) => $router.push({ name: 'ClientOverview', params: { record_id: e.id } })"
                                    placeholder="Search clients"
                                    v-if="loading == false"
                                />
                            </div>
                            <p class="dark-text">Recently viewed</p>
                            <div v-if="recentClients.length > 0">
                                <div v-for="(client, clientKey) in recentClients" :key="clientKey">
                                    <router-link :to="{ name: 'ClientOverview', params: { record_id: client.id } }">{{
                                        `${client.last_name} ${client.first_name}`
                                    }}</router-link>
                                </div>
                            </div>
                            <div v-if="recentClients.length == 0">You have not yet visited any client charts</div>
                        </div>
                        <div class="dashboard-forms card-block">
                            <h2 class="section-header">Recently Viewed Forms</h2>

                    </div>
                    <div class="w-33 m-3 p-2 section section opacity-95">

                    </div>
                    <div class="w-33 m-3 p-2 section w-25 section opacity-95">
                       
                    </div>
                </div>
                <div class="d-flex text-center clinician-dash-section-3">
                    <div class="w-67 m-3 p-2 section section opacity-95">

                    </div>
                    <div class="w-33 m-3 p-2 section section opacity-95">

                    </div>
                     
                </div>
               
            </div>
        </div> -->
        <DeleteReminderAlert :open="showDeleteAlert" @cancel="showDeleteAlert = false" @handleDelete="deleteUserReminder"></DeleteReminderAlert>
    </div>
    
    <Loading v-else />
    
    <!-- <div class="dashboard" v-if="$store.state.initialized == true && loading == false">
        <p class="section-header">Welcome, <b>{{ this.$store.state.user.name }}</b>. Today is {{ today }}.</p>
        <div class="dashboard-alerts card-block">
            <div class="flex center space-between">
                <div class="flex center">
                    <h2 class="section-header right-30">Alerts</h2>
                    <template v-if="$store.state.user.role_id == 1">
                    <button class="secondary right-10" style="padding:3px 10px;" @click="toggle_mine_all">
                        <div v-if="all_alerts">
                            <span class="material-icons-outlined">filter_list</span> Show My Alerts
                        </div>
                        <div v-else>
                            <span class="material-icons-outlined">filter_list</span> Show Company Alerts
                        </div>
                    </button>
                    <div v-if="all_alerts"><i>(Viewing Company Alerts)</i></div>
                    <div v-else><i>(Viewing My Alerts)</i></div>
                    </template>
                </div>
                <a class="text-link" href="/alerts"
                    >See details for all alerts <span class="material-icons-outlined">arrow_forward</span></a
                >
            </div>
            <div class="grid">
                <div class="dash-alerts-wrapper">
                    <DashAlert
                        v-for="(alert_type, key) in alert_types"
                        :key="key"
                        :alert_type="alert_type"
                        :all_alerts="all_alerts"
                    ></DashAlert>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="schedule card-block">
                <h2 class="section-header">Schedule Preview</h2>
                <div class="overview-appointments">
                    <Agenda />
                    <div class="align-right top-10">
                        <router-link class="text-link margin-0" :to="{ name: 'Calendar' }"
                            >View Full Calendar</router-link
                        >
                    </div>
                </div>
            </div>
            <div class="flex wrap right-col">
                <div class="left-col">
                    <div class="dashboard-clients card-block">
                        <h2 class="section-header">Members</h2>
                        <div class="dash-client-search">
                            <Multiselect
                                v-model="client"
                                :options="clients"
                                track-by="id"
                                :custom-label="clientDesc"
                                @select="(e) => $router.push({ name: 'ClientOverview', params: { record_id: e.id } })"
                                placeholder="Search clients"
                                v-if="loading == false"
                            />
                        </div>
                        <p class="dark-text">Recently viewed</p>
                        <div v-if="recentClients.length > 0">
                            <div v-for="(client, clientKey) in recentClients" :key="clientKey">
                                <router-link :to="{ name: 'ClientOverview', params: { record_id: client.id } }">{{
                                    `${client.last_name} ${client.first_name}`
                                }}</router-link>
                            </div>
                        </div>
                        <div v-if="recentClients.length == 0">You have not yet visited any client charts</div>
                    </div>
                    <div class="dashboard-forms card-block">
                        <h2 class="section-header">Recently Viewed Forms</h2>

                        <div v-for="(form, formKey) in recentForms" :key="formKey">
                            <router-link :to="goToRecentForms(form)">
                                {{ form.string }}
                            </router-link>
                        </div>
                        <div v-if="recentForms.length == 0">You have not yet visited any forms</div>
                    </div>
                </div>
                <div class="right-col dashboard-reminders card-block">
                    <h2 class="section-header">Reminders</h2>
                    <div
                        class="reminder"
                        v-for="(reminder, reminderIndex) in reminders"
                        :key="reminder.id"
                        :id="`block-${reminder.id}`"
                    >
                        <button @click="deleteReminder(reminderIndex)">×</button>
                        <textarea v-model="reminder.text"> </textarea>
                    </div>
                    <button class="no-bg text-button" @click="addReminder()"><span class="material-icons purple">add_box</span> Add new</button>
                </div>
            </div>
        </div>
    </div>
    <Loading v-else /> -->
    <!-- <div v-else>Testing</div> -->

</template>

<script>
    import { mapGetters } from 'vuex';
    import DashAlert from '@/components/dash/DashAlert';
    import DeleteReminderAlert from '@/components/dash/DeleteReminderAlert';
    import { companies, clients, notes, users, form_data, file, reminders } from '@/util/apiRequests'; // ,appts
    import { generateId, debounce } from '@/util/genericUtilityFunctions';
    import dayjs from '@/util/dayjs';
    import Agenda from '@/components/calendar/Agenda.vue';
    import CurrencyInput2 from '@/components/general/inputs/CurrencyInput';
    import Loading from '@/components/general/loading/loading.vue';
    import DynamicImage from '@/components/general/image/Image.vue';
    import ScheduleForDashboard from '@/components/calendar/ScheduleForDashboard.vue';
    import { navItem } from 'aws-amplify';
    import moment from 'moment';
    import { Roles } from '@/util/globalConstants'
import PatientCountChart from '../../components/dashboard/PatientCountChart.vue';

    export default {
        components: {
    CurrencyInput2,
    DashAlert,
    Agenda,
    Loading,
    DynamicImage,
    DeleteReminderAlert,
    ScheduleForDashboard,
    PatientCountChart
},

        name: 'Dashboard',
        data() {
            return {
                time: dayjs().format('DD/MM/YYYY'),
                today: '',
                clients: [],
                client: null,
                recentClients: [],
                recentForms: [],
                generateId,
                reminders: [],
                roles:Roles,
                //for just incase
                remindersLoaded: false,
                // Removed Missing Document and Client from the alert types array
                alert_types: ['supervisor_signature', 'progress_note', 'outstanding_balance', 'ready_to_send'],
                appointments: [],
                loading: true,
                all_alerts: 0,
                reminderForm: false,
                userReminders: null,
                reminderData: {
                    reminder: null,
                    due_date: null,
                    priority: ''
                },
                reminderId: null,
                showDeleteAlert: false,
                alertsCount: 0,
                serviceCoordinatorsCount: 0
            };
        },
        methods: {

            handleTime(e){
            },
            goToRecentForms(form) {
                let param = { [`${form.nav_key}`]: form.id };
                return { name: form.component_name, params: param };
            },
            clientDesc({ name }) {
                if (name) {
                    name = name.split(' ').reverse();
                    name[0] = `${name[0]},`;
                    return name.join(' ');
                }
                return 'no name';
            },
            async getClients() {
                try {
                    let result = await this.$api.get(companies.getClients(this.$store.state.user.company_id));
                    this.clients = result.data;
                } catch (error) {
                    // this.$toasted.error('Could not retrieve clients.');
                }
            },
            async getRecentlyViewedClientsInfo() {
                try {
                    let ids = this.$store.state.recent.recentClients.map((element) => {
                        return Number(element.id);
                    });

                    let result = await this.$api.post(clients.getClientsByIds(), { ids: ids });
                    result = result.data.sort(function(a, b) {
                        return ids.indexOf(a.id) - ids.indexOf(b.id);
                    });
                    this.recentClients = result;
                    for (const el of result) {
                        let elementIndex = this.recentClients.findIndex(e => e.id === el.id);
                        if( el.license_id ) {
                            let result = await this.$api.get(file.getFile(el.client_profile_photo_file_id));
                            let profileImage = result.data.file.Body;
                            this.recentClients[elementIndex].profileImage = profileImage ? `data:image/png;base64, ${profileImage}` : 'no-image';
                        }
                        else {
                            this.recentClients[elementIndex].profileImage = 'no-image';
                        }
                    }
                } catch (error) {
                    //
                }
            },
            async getForm(page, id, key) {
                try {
                    let form = {};
                    // if (page == 'ProgressNote') {
                    if (['ProgressNote', 'ChartNote'].includes(page)) {
                        let result = await this.$api.get(notes.getNote(id));
                        form = result?.data.info[0];
                        form.component_name = page;
                        form.page_title = page === 'ProgressNote' ? 'Progress Note' : 'Chart Note';
                        form.nav_key = key;
                    } else {
                        //Get the form
                        let result = await this.$api.get(form_data.getForm(id));
                        //Now that we got the form, concat the page to it
                        // result.data?.info.page_name = page;
                        form = result.data.info[0];
                        form.page_name;
                        form.nav_key = key;
                    }

                    return form;
                } catch (err) {

                    return;
                }
            },
            async getRecentlyViewFormsInfo() {
                try {
                    let forms = this.$store.state.recent.recentForms;
                    let ids = this.$store.state.recent.recentClients.map((element) => {
                        return element.id;
                    })

                    let res = await Promise.all(
                        forms.map((element) => this.getForm(element?.page, element?.id, element?.key))
                    )
                    res = res.filter(e => typeof e == "undefined" ? false : true);
                    const client_ids = res.map((element) => element.client_id);
                    let { data: clientsData } = await this.$api.post(clients.getClientsByIds(), { ids: client_ids });
                    let overall = res
                        .map((element) => {
                            let client = clientsData.find((c) => element.client_id == c.id);
                            let string = `${client?.last_name}, ${client?.first_name} ${element.page_title} ${dayjs(
                                element.dayt_create
                            )
                                .format('MM-DD-YY')
                                .split('-')
                                .join('.')}`;
                            return {
                                firstName: client?.first_name,
                                lastName: client?.last_name,
                                date: dayjs(element.dayt_create)
                                    .format('MM-DD-YY')
                                    .split('-')
                                    .join('.'),
                                title: element.page_title,
                                id: element.id,
                                component_name: element.component_name,
                                string: string,
                                nav_key: element.nav_key
                            };
                        })
                        .sort(function(a, b) {
                            return ids.indexOf(a.id) - ids.indexOf(b.id);
                        });

                    this.recentForms = overall;
                } catch (error) {
                    this.$cl(error);
                }
            },
            async getUserReminders() {
                try {
                    let result = await this.$api.get(users.getUserDetails(this.$store.state.user.id));

                    let reminders = result.data?.reminders ? result.data.reminders : [];
                    if (reminders.length > 0) {
                        this.reminders =
                            reminders.map((element) => {
                                element.id = this.generateId();
                                return element;
                            }) ?? [];
                    }
                    // this.$nextTick(() => {
                    this.remindersLoaded = true;
                    // }, 500);
                } catch (error) {
                    //
                    // this.$nextTick(() => {
                    this.remindersLoaded = true;
                    // }, 500);
                    //this.$toasted.error('Could not get user reminders');
                }
            },
            addReminder() {
                let id = this.generateId();
                this.reminders.push({ id: id, text: '' });
            },
            async updateReminders() {
                try {
                    let reminders = this.reminders
                        .filter((element) => element.text !== '')
                        .map((element) => {
                            return { text: element.text };
                        });
                    this.$api.put(users.updateUser(this.$store.state.user.id), { reminders: JSON.stringify(reminders) });
                }catch(error){
                    this.$cl(error);
                }
            },
            async deleteReminder(index) {
                this.reminders.splice(index, 1);
                let reminders = this.reminders.map((element) => {
                    return { text: element.text };
                });
                this.$api.put(users.updateUser(this.$store.state.user.id), { reminders: JSON.stringify(reminders) });
            },
            formatDateWithoutHours(dayt) {
                return dayjs(dayt).format('MM/DD/YY');
            },
            async getAppointments() {
                // let date2normal = (new_date) => {
                //     let dd = String(new_date.getDate()).padStart(2, '0')
                //     let mm = String(new_date.getMonth() + 1).padStart(2, '0') // January is 0!
                //     let yyyy = new_date.getFullYear()
                //     return yyyy + '-' + mm + '-' + dd
                // }
                // let mdy2normal = function(m, d, y) {
                //     let mdy = new Date(y, m, d)
                //     return date2normal(mdy)
                // }
                let new_date = new Date();
                let m = new_date.getMonth();
                let d = new_date.getDate();
                let y = new_date.getFullYear();
                let seven_days = new Date(y, m, d + 7);

                try {
                    let id = this.$store.state.user.id;
                    const res = await this.$api.get('/appts', {
                        params: {
                            u: id,
                            s: new_date.toJSON(),
                            e: seven_days.toJSON()
                        }
                    });
                    return res.data[0];
                } catch (err) {
                    this.$cl(err);
                }
            },
            async toggle_mine_all(e) {
                if (this.$store.state.user.role_id == 1) {
                    this.all_alerts = 1 - this.all_alerts
                    let temp = this.alert_types.slice();
                    this.alert_types = [];
                    await this.$nextTick(() => {
                        this.alert_types = temp.slice();
                    });
                }
            },
            toggleReminderForm() {
                this.reminderData = {
                    reminder: null,
                    due_date: null,
                    priority: ''
                }
                this.reminderId = null;
                this.reminderForm = !this.reminderForm;
            },
            async getReminders() {
                try {
                    let result = await this.$api.get(reminders.getReminder());
                    this.userReminders = result.data.reminders;
                    return true;
                } catch (error) {
                    this.$toasted.error('Failed to get reminder list !');
                }
            },
            async createUserReminder() {
                try {
                    if( !this.reminderData.reminder || !this.reminderData.due_date || !this.reminderData.priority ) {
                        this.$toasted.error('Please fill all fields !');
                        return;
                    }
                    let reminderObj = this.reminderData;
                    this.resetReminderForm();
                    reminderObj['due_date'] = moment(reminderObj.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    const endpoint = reminders.createReminder();
                    const createReminderResponseData = await this.$api.post(endpoint, reminderObj);
                    if (!createReminderResponseData.data.success) {
                        throw createReminderResponseData.data.error;
                    }
                    this.userReminders.unshift( createReminderResponseData.data.reminder );
                    this.reminderForm = false;
                    this.$toasted.success('Reminder Created Successfully !');
                } catch (error) {
                    this.$toasted.error('Failed to create reminder !');
                }
            },
            async updateUserReminder() {
                try {
                    if( !this.reminderData.reminder || !this.reminderData.due_date || !this.reminderData.priority ) {
                        this.$toasted.error('Please fill all fields !');
                        return;
                    }
                    if( !this.reminderId ) {
                        this.$toasted.error('Reminder id is required !');
                        return;
                    }

                    let reminderObj = this.reminderData;
                    reminderObj['due_date'] = this.getFormattedDate( reminderObj.due_date, 'YYYY-MM-DD', 'DD/MM/YYYY' ); // moment(reminderObj.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    
                    const endpoint = reminders.updateReminder( this.reminderId );
                    const updateReminderResponseData = await this.$api.put(endpoint, reminderObj);
                    if (!updateReminderResponseData.data.success) {
                        throw updateReminderResponseData.data.error;
                    }

                    const currentReminderIndex = this.userReminders.findIndex( el => el.id === this.reminderId );

                    if( currentReminderIndex > -1 ) {
                        const { reminder, due_date, priority } = updateReminderResponseData.data.reminder;
                        this.userReminders[currentReminderIndex].reminder = reminder;
                        this.userReminders[currentReminderIndex].due_date = due_date;
                        this.userReminders[currentReminderIndex].priority = priority;
                    }

                    this.resetReminderForm();
                    
                    this.reminderForm = false;

                    this.$toasted.success('Reminder Updated Successfully !');
                } catch (error) {
                    this.$toasted.error('Failed to update reminder !');
                }
            },
            async deleteUserReminder() {
                try {
                    this.showDeleteAlert = false;
                    if( !this.reminderId ) {
                        this.$toasted.error('Reminder id is required !');
                        return;
                    }
                    const endpoint = reminders.deleteReminder( this.reminderId );
                    const deleteReminderResponseData = await this.$api.delete(endpoint);
                    if (!deleteReminderResponseData.data.success) {
                        throw deleteReminderResponseData.data.error;
                    }

                    const currentReminderIndex = this.userReminders.findIndex( el => el.id === this.reminderId );

                    this.resetReminderForm();

                    if( currentReminderIndex > -1 ) {
                        this.userReminders.splice( currentReminderIndex, 1 );
                    }

                    this.$toasted.success('Reminder Deleted Successfully !');
                } catch (error) {
                    this.$toasted.error('Failed to delete reminder !');
                }
            },
            editReminder( reminderData ) {
                const { reminder, due_date, priority, id: reminderId } = reminderData;
                this.reminderData = {
                    reminder,
                    due_date: moment(due_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
                    priority
                }
                this.reminderId = reminderId;
                this.reminderForm = true;
                document.getElementById('popover-button-variant').style.display = 'none';
            },
            deleteReminderAlert( reminderId ) {
                this.reminderId = reminderId;
                this.showDeleteAlert = true;
                document.getElementById('popover-button-variant').style.display = 'none';
            },
            resetReminderForm() {
                this.reminderData = {
                    reminder: null,
                    due_date: null,
                    priority: null
                }
                this.reminderId = null;
            },
            getFormattedDate(date, currentFormat, expectedFormat) {
                return moment( date, currentFormat ).format( expectedFormat );
            },
            async getAlertsCount() {
                const getAlertTypes = this.alert_types.map( alertType => {
                    return this.$api.post(`/alerts/count/${alertType}`, {all_alerts:this.all_alerts})
                } )
                const alerts = (await Promise.all( getAlertTypes )).map( alert => alert.data );
                this.alertsCount = alerts.reduce( ( prev, curr ) => prev + curr );
                return true;
            },
            async getServiceCoordinators() {
                try {
                    let result = await this.$api.get(users.serviceCoordinators());
                    this.serviceCoordinatorsCount = result.data.serviceCoordinators;
                } catch (error) {
                    this.$toasted.error('Failed to get service coordinators count !');
                }
            }
        },
        async created() {
            await this.$nextTick(() => {
                try {
                    let elem = document.querySelector('.page-body');
                    elem.classList.add('page-body', 'calendar');
                } catch (error) {
                    //
                }
            }, 1000);

            let new_date = new Date();
            let m = new_date.getMonth();
            let d = new_date.getDate();
            let y = new_date.getFullYear();
            const monthNames = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ];
            this.today = monthNames[m] + ' ' + d + ', ' + y;
            try {
                await Promise.all([
                    this.getClients(),
                    this.getRecentlyViewedClientsInfo(),
                    this.getRecentlyViewFormsInfo(),
                    this.getUserReminders(),
                    this.getAppointments(),
                    this.getReminders(),
                    this.getAlertsCount(),
                    this.getServiceCoordinators()
                ]);
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
            // this.$nextTick(() => {
            //     // eslint-disable-next-line no-unused-vars
            //     let specifiedElement = Array.from(document.querySelectorAll('.e-tbar-btn-text')).find(
            //         (el) => el.textContent === 'Today'
            //     );
            // }, 10000);
        },
        computed: {
            ...mapGetters(['availablePages'])
        },
        watch: {
            reminders: {
                deep: true,
                immediate: true,
                handler: debounce(function() {
                    if (this.remindersLoaded == true) {
                        this.updateReminders();
                    }
                }, 500)
            }
        }
    };
</script>
<style scoped>

.section-alert {
    background: #FFEFEF;
}

.add-member-icon-section {
    background: rgba(255, 255, 255, 0.3); 
    border-radius: 50%; 
}

.add-member-section {
    background: #AC2734;
}

.no-content-text {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
    color: #5B5B5B;
}

@media only screen and (max-width: 1350px) {
    .notification-icon {
        font-size: 35px; 
        color: #AC2734;
    }

    .notification-icon-count {
        right: 2px;
        font-size: 10px;
        color: #fff;
        top: 0;
        background: rgb(31, 59, 179);
        padding: 0px 5px;
        border-radius: 50%;
    }

    .alert-title {
        color: #AC2734;
        font-size: 20px;
    }

    .alert-switch-section {
        background: rgb(172, 39, 52);
        border-radius: 50px;
        font-size: 8px; 
        font-weight: 600;
    }

    .alert-switch-toggle {
        background: rgb(255, 255, 255);
        color: rgb(172, 39, 52);
        border-radius: 50%; 
        width: 18px;
    }

    .alert-view-section {
        width: 200px;
        color: #AC2734;
        font-size: 10px; 
        font-weight: 600;
    }

    .view-alerts-text {
        color: rgb(172, 39, 52); 
        text-decoration: none;
        font-weight: 600;
        font-size: 9px;
    }

    .eye-icon {
        color: #000000;
        padding: 0 5px;
        border: none;
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
    }

    .member-image {
        width: 25px; 
        height: 25px; 
        border-radius: 50%;
    }

    .member-icon {
        font-size: 22px; 
        border-radius: 50%;
        background: rgb(31, 59, 179);
        color: #FFFFFF;
        cursor: pointer;
        padding: 2px;
    }
    
    .section-body-odd {
        height: 240px; 
        overflow-x: hidden; 
        overflow-y: auto;
        border-radius: 15px;
    }

    .section-body-even {
        height: 250px; 
        overflow: auto; 
        border-radius: 15px;
    }

    .add-member-icon {
        font-size: 60px; 
        color: #FFFFFF;
    }

    .add-member-text {
        font-size: 10px; 
        font-family: 'Roboto'; 
        font-weight: 700; 
        color: #ffffff; 
    }
}

@media only screen and (min-width: 1350px) {
    .notification-icon {
        font-size: 50px; 
        color: #AC2734;
    }

    .notification-icon-count {
        right: 0; 
        font-size: 16px; 
        color: #fff; 
        top: -2px; 
        background: rgb(31, 59, 179); 
        padding: 0px 8px; 
        border-radius: 50%;
    }

    .alert-title {
        color: #AC2734; 
        font-size: 30px;
    }

    .alert-switch-section {
        background: rgb(172, 39, 52);
        border-radius: 50px;
        font-size: 12px; font-weight: 600;
    }

    .alert-switch-toggle {
        background: rgb(255, 255, 255);
        color: rgb(172, 39, 52);
        border-radius: 50%; 
        width: 20px;
    }

    .alert-view-section {
        width: 200px;
        color: #AC2734;
        font-size: 15px; 
        font-weight: 600;
    }

    .view-alerts-text {
        color: rgb(172, 39, 52); 
        text-decoration: none;
        font-weight: 600;
    }

    .eye-icon {
        color: #000000;
        padding: 1px 10px;
        border: none;
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
    }

    .member-image {
        width: 35px; 
        height: 35px; 
        border-radius: 50%;
    }

    .member-icon {
        font-size: 30px; 
        border-radius: 50%;
        background: rgb(31, 59, 179);
        color: #FFFFFF;
        cursor: pointer;
        padding: 2px;
    }

    .section-body-odd {
        height: 370px; 
        overflow-x: hidden; 
        overflow-y: auto; 
        border-radius: 15px;
    }

    .section-body-even {
        height: 400px; 
        overflow: auto; 
        border-radius: 15px;
    }

    .add-member-icon {
        font-size: 95px; 
        color: #FFFFFF;
    }

    .add-member-text {
        font-size: 15px; 
        font-family: 'Roboto'; 
        font-weight: 700; 
        color: #ffffff; 
    }
}

.add-user a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-user a .material-symbols-outlined {
    font-size: 18px !important;
}
</style>